<template>
  <div ref="element">
    <div>
      <div class="flex items-center justify-between">
        <label class="block text-sm font-medium mb-1"
               :class="{'text-red-300': errorMessages.length > 0}"
               :for="name"
        >
          {{ label }}
        </label>
        <div class="relative ml-2" v-if="help.length > 0">
          <Icon name="fluent:chat-help-24-filled" v-tippy="{content: help, placement: 'left', arrow: true}" />
        </div>
      </div>
      <textarea class="form-input w-full"
             :class="{'border-red-300': errorMessages.length > 0}"
             :name="name"
             :id="name"
             :placeholder="placeholder"
             v-model="modelValue"
             :disabled="disabled"
      ></textarea>
    </div>
    <div class="text-xs mt-1 text-red-500" v-for="(message, i) in errorMessages" :key="`error-${props.name}-${i}`">
      {{ t(message) }}
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  modelValue: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  errorMessages: {
    type: Array,
    default: [],
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  help: {
    type: String,
    default: '',
  }
});
const {t} = useI18n();
const element = ref();
defineExpose({
  element: element,
});

const emit = defineEmits(['update:modelValue']);

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});
</script>

<style scoped>

</style>
